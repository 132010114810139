import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { DialogTitle, Input, Separator } from '@chakra-ui/react';
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from '../../../components/ui/menu.js';
import {
  faAngleDown,
  faCircleInfo,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Alert } from '../../../components/ui/alert.js';
import { Button } from '../../../components/ui/button.js';
import { Database } from 'common-ts';
import { Field } from '../../../components/ui/field.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '../../../components/ui/tooltip.js';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { fetchApi } from '../../../utils/useApi.js';
import { t } from 'i18next';
import { useBoundStore } from '../../../store/useBoundStore.js';

type ShareCollectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccessChange: (
    accessType: Database['public']['Enums']['access_type'],
    userId: string | null,
    callback?: () => void
  ) => void;
  selectedCollectionId: string;
  isPublicForWorkspace: boolean;
};

function ShareCollectionModal({
  isOpen,
  onClose,
  onAccessChange,
  selectedCollectionId,
  isPublicForWorkspace,
}: ShareCollectionModalProps) {
  const supabase = useBoundStore((state) => state.supabase);
  const userInfo = useBoundStore((state) => state.userInfo);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const workspaceUserType = useBoundStore((state) => state.workspaceUserType);
  const updateExtendedBuckets = useBoundStore(
    (state) => state.updateExtendedBuckets
  );
  const modalRef = useRef(null);

  const [email, setEmail] = useState('');
  const [selectedRights, setSelectedRight] =
    useState<Database['public']['Enums']['access_type']>('VIEW');
  const [sharedBuckets, setSharedBuckets] = useState<
    Database['public']['Views']['shared_buckets']['Row'][]
  >([]);
  const [bucketOwner, setBucketOwner] =
    useState<Database['public']['Views']['shared_bucket_owners']['Row']>();
  const [showUnshareWarning, setShowUnshareWarning] = useState<
    string | undefined
  >(undefined);
  const [isShareError, setIsShareError] = useState<boolean>(false);
  const [shareErrorText, setShareErrorText] = useState<string>('');
  /**
   * Memoized access rights on this collection
   */
  const viewer = useMemo(() => {
    return sharedBuckets.some(
      (sb) => sb.user_id === userInfo?.userId && sb.access_type === 'VIEW'
    );
  }, [sharedBuckets]);

  /**
   * Sets the share error text based on the error key.
   * @param errorKey - Key identifying the error type.
   */
  const setErrorByKey = (errorKey?: number) => {
    const errorMessages: { [key: number]: string } = {
      100: t('shareCollectionModal.shareErrorMessages.userNotFound'),
      101: t('shareCollectionModal.shareErrorMessages.selfShare'),
      102: t('shareCollectionModal.shareErrorMessages.alreadyShared'),
      103: t('shareCollectionModal.shareErrorMessages.limitReached'),
      104: t('shareCollectionModal.shareErrorMessages.userLicenseError'),
      // TODO remove this, its not possible any more
      105: t('shareCollectionModal.shareErrorMessages.foreignUserLicenseError'),
      106: t(
        'shareCollectionModal.shareErrorMessages.targetUserNotPartOfWorkspaceError'
      ),
      107: t('shareCollectionModal.shareErrorMessages.invalidEmail'),
    };

    if (errorKey === undefined) {
      setShareErrorText(
        t('shareCollectionModal.shareErrorMessages.unknownError')
      );
      return;
    }

    setShareErrorText(
      errorMessages[errorKey] ||
        t('shareCollectionModal.shareErrorMessages.unknownError')
    );
  };

  /**
   * Handle sharing a collection with a user.
   */
  const handleAddShare = async () => {
    if (!email) return;

    const resp = await fetchApi(supabase, '/share', '/bucket', {
      method: 'POST',
      bucketId: selectedCollectionId,
      accessType: selectedRights,
      email,
      workspaceId,
    });

    if (resp.success) {
      setEmail('');
      await updateSharedBuckets();
    } else {
      setIsShareError(true);
      setErrorByKey(resp.data?.errorKey);
    }
  };

  /**
   * Move the collection to a workspace.
   */
  const updateWorkspacePublicSetting = async (value: boolean) => {
    const res = await fetchApi(
      supabase,
      '/share',
      '/bucket_public_in_workspace',
      {
        method: 'POST',
        bucketId: selectedCollectionId,
        publicForWorkspace: value,
        workspaceId,
      }
    );

    if (res.success) {
      await updateExtendedBuckets();
      onClose();
    }
  };

  const handleRemoveFromWorkspace = async () => {
    const isOwner = bucketOwner?.owner_user_id === userInfo?.userId;
    if (!showUnshareWarning) {
      if (isOwner) {
        setShowUnshareWarning(
          t('shareCollectionModal.unshareWarningTextOwner')
        );
      } else {
        setShowUnshareWarning(
          t('shareCollectionModal.unshareWarningTextForeign', {
            originalOwner: bucketOwner?.owner_email,
          })
        );
      }
    } else {
      updateWorkspacePublicSetting(false);
    }
  };

  /**
   * Handle removing a share from a collection.
   * @param shareId - id of the share to be removed.
   */
  const handleRemoveShare = async (shareId: string | null) => {
    if (shareId) {
      const resp = await fetchApi(supabase, '/share', '/unshare_bucket', {
        method: 'POST',
        sharedBucketId: shareId,
        workspaceId,
      });
      if (resp.success) {
        await updateSharedBuckets();
      } else {
        console.error(resp.error);
      }
    }
  };

  /**
   * Fetches and updates the shared buckets for the selected collection ID.
   */
  const updateSharedBuckets = async () => {
    const { data: sharedBuckets, error } = await supabase
      .from('shared_buckets')
      .select('*')
      .eq('bucket_id', selectedCollectionId);

    if (error) {
      console.error(error);
      return;
    }

    if (sharedBuckets) {
      setSharedBuckets(sharedBuckets);
    }
  };

  /**
   * Effect hook for fetching the owner of this collection
   */
  useEffect(() => {
    supabase
      .from('shared_bucket_owners')
      .select('*')
      .eq('bucket_id', selectedCollectionId)
      .then((res) => {
        if (res.data?.length && res.data[0]) {
          setBucketOwner(res.data[0]);
        }
      });
  }, [selectedCollectionId, isPublicForWorkspace]);

  /**
   * Effect hook for handling when the modal is opened or closed.
   */
  useEffect(() => {
    if (isOpen) {
      updateSharedBuckets();
    }
    setIsShareError(false);
    setEmail('');
    setShareErrorText('');
    setShowUnshareWarning(undefined);
  }, [isOpen]);

  /**
   * Effect hook to reset sharing error when email is changed.
   */
  useEffect(() => {
    setIsShareError(false);
  }, [email]);

  function getName(
    accessType: Database['public']['Enums']['access_type'] | 'OWN'
  ) {
    return accessType === 'EDIT'
      ? t('shareCollectionModal.canEdit')
      : accessType === 'VIEW'
        ? t('shareCollectionModal.canView')
        : t('shareCollectionModal.owner');
  }

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => !open && onClose()}
      size="lg"
    >
      <DialogBackdrop />
      <DialogContent minWidth="25%" ref={modalRef}>
        <DialogHeader>
          <DialogTitle>{t('shareCollectionModal.header')}</DialogTitle>
        </DialogHeader>{' '}
        <DialogCloseTrigger />
        <DialogBody className="mb-4">
          <div className="flex flex-col gap-4">
            <div className="flex w-full items-start gap-2">
              <Tooltip
                content={
                  viewer
                    ? t('fileManagerPanel.disabledReason')
                    : isPublicForWorkspace
                      ? t('fileManagerPanel.cantShareIfPublicForWorkspace')
                      : ''
                }
                disabled={!(viewer || isPublicForWorkspace)}
                openDelay={500}
                closeOnClick={false}
              >
                <Field invalid={isShareError} errorText={shareErrorText}>
                  <Input
                    colorPalette={'maia-accent'}
                    disabled={viewer || isPublicForWorkspace}
                    placeholder={t(
                      'shareCollectionModal.emailInputPlaceholder'
                    )}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.trim())}
                    onSubmit={handleAddShare}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleAddShare();
                      }
                    }}
                    size={'sm'}
                  />
                </Field>
              </Tooltip>
              <MenuRoot>
                <MenuTrigger>
                  <Tooltip
                    content={
                      viewer
                        ? t('fileManagerPanel.disabledReason')
                        : isPublicForWorkspace
                          ? t('fileManagerPanel.cantShareIfPublicForWorkspace')
                          : ''
                    }
                    disabled={!(viewer || isPublicForWorkspace)}
                    openDelay={500}
                    closeOnClick={false}
                  >
                    <Button className="border-maia-border flex w-40 justify-between rounded-sm border bg-transparent text-sm font-normal text-black hover:bg-gray-100 focus:bg-gray-100">
                      {getName(selectedRights)}
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Button>
                  </Tooltip>
                </MenuTrigger>
                <MenuContent portalRef={modalRef} className="w-40 px-0">
                  <MenuItem
                    value="VIEW"
                    fontSize={'14px'}
                    onClick={() => setSelectedRight('VIEW')}
                  >
                    {getName('VIEW')}
                  </MenuItem>
                  <MenuItem
                    value="EDIT"
                    fontSize={'14px'}
                    onClick={() => setSelectedRight('EDIT')}
                  >
                    {getName('EDIT')}
                  </MenuItem>
                </MenuContent>
              </MenuRoot>
              <Tooltip
                content={
                  viewer
                    ? t('fileManagerPanel.disabledReason')
                    : isPublicForWorkspace
                      ? t('fileManagerPanel.cantShareIfPublicForWorkspace')
                      : ''
                }
                openDelay={500}
                disabled={!(viewer || isPublicForWorkspace)}
                closeOnClick={false}
              >
                <Button
                  className="font-medium"
                  colorPalette="maia-accent"
                  onClick={handleAddShare}
                  disabled={viewer || isPublicForWorkspace}
                  size={'sm'}
                  px={'6'}
                >
                  {t('shareCollectionModal.shareButton')}
                </Button>
              </Tooltip>
            </div>

            <div className="text-chakra-gray-500 ml-1 flex items-center gap-2 text-xs font-medium">
              <FontAwesomeIcon icon={faCircleInfo} />{' '}
              {t('shareCollectionModal.onlyProfessional')}
            </div>

            <div className="flex w-full flex-col gap-1 pb-4">
              {bucketOwner && (
                <div
                  key={bucketOwner.bucket_id}
                  className="hover:bg-maia-blue-100 group flex h-9 items-center justify-between rounded-md px-3"
                >
                  <div className="flex max-w-[250px] flex-grow items-center justify-between">
                    <div>{bucketOwner.owner_email}</div>
                    {/* {bucketOwner.plan && workspaceType !== 'COMPANY' && (
                      <LicenseBadge
                        userPlan={bucketOwner.plan}
                        loading={false}
                      />
                    )} */}
                  </div>
                  <MenuRoot>
                    <Tooltip
                      content={t('shareCollectionModal.ownerOwnRemoval')}
                      openDelay={500}
                    >
                      <MenuTrigger className="font-normal" disabled>
                        {getName('OWN')}
                      </MenuTrigger>
                    </Tooltip>
                  </MenuRoot>
                </div>
              )}
              {!isPublicForWorkspace &&
                sharedBuckets
                  .sort((a) => (a.user_id === userInfo?.userId ? 1 : 0))
                  .map((bucket) => {
                    return (
                      <div
                        key={bucket.user_id}
                        className={`hover:bg-maia-blue-100 group flex h-9 items-center justify-between rounded-md px-3 ${
                          bucket.user_id === userInfo?.userId
                            ? 'bg-maia-purple-100'
                            : ''
                        }`}
                      >
                        <div className="flex max-w-[250px] flex-grow items-center justify-between">
                          <div>{bucket.email}</div>
                          {/* TODO: adjust to workspaces (not trivial). In case of sharing between private workspaces, license of other user's private workspace needs to be shown */}
                          {/* {bucket.plan && workspaceType !== 'COMPANY' && (
                            <LicenseBadge
                              userPlan={bucket.plan}
                              loading={false}
                            />
                          )} */}
                        </div>
                        <MenuRoot>
                          <MenuTrigger
                            className="min-w-0 font-normal"
                            disabled={
                              viewer && bucket.user_id !== userInfo?.userId
                            }
                          >
                            <div className="flex items-center gap-2">
                              <div className="min-w-0 overflow-hidden text-ellipsis whitespace-nowrap">
                                {getName(bucket.access_type ?? 'OWN')}
                              </div>
                              <FontAwesomeIcon icon={faAngleDown} />
                            </div>
                          </MenuTrigger>
                          <MenuContent portalRef={modalRef}>
                            <MenuItem
                              value="EDIT"
                              disabled={viewer || bucket.plan === 'FREE'}
                              onClick={() =>
                                onAccessChange(
                                  'EDIT',
                                  bucket.user_id,
                                  updateSharedBuckets
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                className={`pr-2 ${
                                  bucket.access_type !== 'EDIT' && 'opacity-0'
                                }`}
                              />
                              {getName('EDIT')}
                            </MenuItem>
                            <MenuItem
                              disabled={viewer}
                              onClick={() =>
                                onAccessChange(
                                  'VIEW',
                                  bucket.user_id,
                                  updateSharedBuckets
                                )
                              }
                              value="VIEW"
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                className={`pr-2 ${
                                  bucket.access_type !== 'VIEW' && 'opacity-0'
                                }`}
                              />
                              {getName('VIEW')}
                            </MenuItem>
                            <MenuItem
                              value="REMOVE"
                              onClick={() => handleRemoveShare(bucket.share_id)}
                              className="text-maia-support-red select-none"
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className={`pr-2 ${
                                  bucket.access_type !== 'VIEW' && 'opacity-0'
                                }`}
                              />
                              {t(
                                'fileManagerPanel.deleteShareModalRemoveButton'
                              )}
                            </MenuItem>
                          </MenuContent>
                        </MenuRoot>
                      </div>
                    );
                  })}
            </div>
            <Separator />
            {showUnshareWarning ? (
              <Alert
                title={t('shareCollectionModal.unshareWarningTitle')}
                status="warning"
                mt="3"
              >
                {showUnshareWarning}
              </Alert>
            ) : null}
            {bucketOwner?.workspace_id === workspaceId &&
            workspaceUserType !== 'USER' ? (
              <div className="flex w-full items-center justify-end">
                {isPublicForWorkspace ? (
                  <Button
                    colorPalette="red"
                    px={'2'}
                    onClick={handleRemoveFromWorkspace}
                  >
                    {t('shareCollectionModal.removeFromWorkspaceButton')}
                  </Button>
                ) : (
                  <Button
                    colorPalette="maia-accent"
                    px={'6'}
                    onClick={() => updateWorkspacePublicSetting(true)}
                  >
                    {t('shareCollectionModal.moveToWorkspaceButton')}
                  </Button>
                )}
              </div>
            ) : null}
          </div>
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
}

export default ShareCollectionModal;
